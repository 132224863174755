import React, { Component } from 'react';
import './App.scss';
import { isMobile } from 'react-device-detect';
import Mobile from './sections/Mobile/Mobile';
import Desktop from './sections/Desktop/Desktop';

class App extends Component {

    componentDidMount() {
        // const scriptService = document.createElement('script');
        // scriptService.src = "https://w183129.yclients.com/widgetJS";
        // scriptService.type = "text/javascript";
        // scriptService.charset = "UTF-8";
        // document.documentElement.appendChild(scriptService);
    }

    render() {
        return isMobile ? <Mobile/> : <Desktop/>;
    }
}

export default App;
