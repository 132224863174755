import React from 'react';
import './Header.scss';
import Menu from '../Menu/Menu';
import logo from '../../../assets/liberty_logo.svg';
import razor from '../../../assets/icons/razor.svg';
import facebook from '../../../assets/icons/facebook.svg';
import instagram from '../../../assets/icons/instagram.svg';

function Header(props) {
  return (
    <section className="screen header">
      <div className="contacts">
        <div className="address">
          київ, вул. антоновича, 125в
          <br/>
            <a href="tel:+380688233833" >+38 (068) 823 38 33</a>
        </div>
        <div className="barbershop">
          <img src={razor} alt="" />
          <h1>Barbershop</h1>
        </div>
        <div className="sn-wh-wrap">
          <div className="working-hours">
            пн - нд
            <br/>
            10:00 - 21:00
          </div>
          <div className="social-networks">
              <a href="https://www.facebook.com/liberty.barbershop.39" target="_blank" rel="noopener noreferrer">
                  <img className="sn-icon" src={facebook} alt="facebook" />
              </a>
              <a href="https://www.instagram.com/barbershop_liberty/" target="_blank" rel="noopener noreferrer">
                  <img className="sn-icon" src={instagram} alt="instagram" />
              </a>
          </div>
        </div>
      </div>
      <img className="logo" src={logo} alt="Liberty" />
      <Menu/>
    </section>
  );
}

export default Header;
