import React from 'react';
import './Menu.scss';
import burger from '../../../assets/icons/burger.svg';
import cross from '../../../assets/icons/cross.svg';

class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpened: false
        };

        this.menuToggle = this.menuToggle.bind(this);
    }

    menuToggle() {
        this.setState((state) => {
            return { menuOpened: !state.menuOpened };
        });
    }

    render() {
        const hidden = this.state.menuOpened ? '' : 'hidden';
        const menuScreen = (
            <nav className={'main-nav-m ' + hidden} onClick={this.menuToggle}>
                <span className="wlaunchWidgetLoaderControl">Записатись</span>
                <a href="#barbers">Майстри</a>
                <a href="#prices">Ціни</a>
                <a href="#location">Як нас знайти?</a>
            </nav>
        );
        return (
            <>
                <img className="nav-open-button-m" src={this.state.menuOpened ? cross : burger} onClick={this.menuToggle} alt=""/>
                {menuScreen}
            </>
        );
    }
}

export default Menu;
