import React from 'react';
import './Prices.scss';

function Prices(props) {
  const services = props.services;
  
  const servicesList = services.map(item => {
	  let wrapClass = 'icon-wrap';
	  if (item.icons.length > 1) {
		  wrapClass += ' combined';
	  }
    return (
      <div key={item.name} className="item-row">
	      <div className={wrapClass}>
		      { item.icons.map((icon, i) => (
			      <img key={i} className={'icon'} src={icon} alt="" />
		      )) }
	      </div>
        <span className="name">{item.name}</span>
        <span className="price">{item.price}</span>
      </div>
    );
  });
  
  
  return (
    <section className="screen prices" id="prices">
      <h1>Ціни</h1>
      <div className="price-list">
        {servicesList}
      </div>
    </section>
  );
}

export default Prices;
