import React, {useEffect, useState} from 'react';
import Header from './Header/Header';
import Barbers from './Barbers/Barbers';
import Prices from './Prices/Prices';
import Location from './Location/Location';
import Appointment from './Appointment/Appointment';
import Footer from './Footer/Footer';

import {BARBERS, getServices} from '../../services/data';

const Desktop = () => {
    const [services, setServices] = useState([]);
    useEffect(async () => {
        setServices(await getServices());
    }, []);

    return (
        <>
            <Header/>
            <Barbers barbers={BARBERS}/>
            <Prices services={services}/>
            <Location/>
            <Appointment/>
            <Footer/>
        </>
    );
}

export default Desktop;