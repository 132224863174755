import haircut from '../assets/icons/haircut.svg';
import clipper from '../assets/icons/clipper.svg';
import beard from '../assets/icons/beard.svg';
import razor from '../assets/icons/service-razor.svg';
import kidHaircut from '../assets/icons/kid-haircut.svg';
import dyeing from '../assets/icons/dyeing.svg';

import barber1 from '../assets/photos/barber_1.jpeg';
import barber2 from '../assets/photos/barber_2.jpeg';
import barber3 from '../assets/photos/barber_3.jpeg';

export const BARBERS = [
    {name: 'Антон Велесь', photo: barber1},
    {name: 'Геннадій Тафратов', photo: barber2},
    {name: 'Ілля Волошенюк', photo: barber3},
];

export const getServices = async() => {
    const response = await fetch(`${process.env.PUBLIC_URL}/prices.json`);
    const prices = await response.json()
    return [
        {icons: [haircut], ...prices.haircut},
        {icons: [haircut, beard], ...prices.haircut_plus_beard},
        {icons: [clipper], ...prices.buzz_cut},
        {icons: [clipper, beard], ...prices.buzz_cut_plus_beard},
        {icons: [beard], ...prices.beard_cut},
        {icons: [kidHaircut], ...prices.kids_cut},
        {icons: [razor], ...prices.shaving},
        {icons: [dyeing], ...prices.camouflage},
    ];
}

export const GMAP_API_KEY = 'AIzaSyBOWVTszJkBD4cOaw0T9Cg-awS4Pu14-yw';
