import React from 'react';
import './Header.scss';
import logo from '../../../assets/liberty_logo.svg';
import razor from '../../../assets/icons/razor.svg';
import Menu from '../Menu/Menu';

function Header(props) {
    return (
        <section className="screen header-m">
            <Menu/>
            <div className="barbershop">
                <img src={razor} alt="" />
                <h1>Barbershop</h1>
            </div>
            <div className="address">
                київ, вул. антоновича, 125в
                <br/>
                <a href="tel:+380688233833" >+38 (068) 823 38 33</a>
            </div>
            <div className="working-hours">
                пн - нд
                <br/>
                10:00 - 21:00
            </div>
            <img className="logo" src={logo} alt="Liberty" />
            <span className="appointment-button wlaunchWidgetLoaderControl">
                Записатись
            </span>
        </section>
    );
}

export default Header;
