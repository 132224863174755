import React from 'react';
import './Prices.scss';

function Prices(props) {
  const servicesList = props.services.map(item => {
    return (
      <div key={item.name} className="item-row">
        <span className="name">{item.name}</span>
        <span className="price">{item.price}</span>
      </div>
    );
  });
  
  
  return (
    <section className="screen prices-m" id="prices">
      <h1>Ціни</h1>
      <div className="price-list">
        {servicesList}
      </div>
    </section>
  );
}

export default Prices;
