import React from 'react';
import './Menu.scss';

function Menu(props) {
    return (
        <nav className="main-nav">
            <span className="wlaunchWidgetLoaderControl">Записатись</span>
            <a href="#barbers">Майстри</a>
            <a href="#prices">Ціни</a>
            <a href="#location">Як нас знайти?</a>
        </nav>
    );
}

export default Menu;
