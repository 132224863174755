import React from 'react';
import './Footer.scss';
import facebook from '../../../assets/icons/facebook.svg';
import instagram from '../../../assets/icons/instagram.svg';

function Footer(props) {
  return (
    <footer className="screen footer-m">
      <div className="working-hours">
        пн - нд
        <br/>
        10:00 - 21:00
      </div>
      <div className="address">
        київ, вул. антоновича, 125в
        <br/>
          <a href="tel:+380688233833" >+38 (068) 823 38 33</a>
      </div>
      <div className="email">
          <a href="mailto:barbershopliberty2019@gmail.com">barbershopliberty2019@gmail.com</a>
      </div>
      <div className="sn-wrap">
        <a href="https://www.facebook.com/liberty.barbershop.39" target="_blank" rel="noopener noreferrer">
            <img src={facebook} alt="facebook" />
        </a>
        <a href="https://www.instagram.com/barbershop_liberty/" target="_blank" rel="noopener noreferrer">
            <img src={instagram} alt="instagram" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
