import React from 'react';
import './Barbers.scss';

import instagram from '../../../assets/icons/instagram_brown.svg';

function Barbers(props) {
    const barbersElements = props.barbers.map((b, i) => {
        if (i === 0) {
            return <div key={b.name} className="barber">
                <h1>Майстри</h1>
                <img className="picture" src={b.photo} alt={b.name} />
                <div className="divider" />
                <div className="name">{b.name}</div>
                {/*<img className="instagram" src={instagram} alt=""/>*/}
            </div>
        }
        return (
            <div key={b.name} className="barber">
                <img className="picture" src={b.photo} alt={b.name} />
                <div className="divider" />
                <div className="name">{b.name}</div>
                {/*<img className="instagram" src={instagram} alt=""/>*/}
            </div>
        );
    });

    return (
        <section className="screen barbers-m" id="barbers">
            <div className="barbers-wrap">
                {barbersElements}
            </div>
        </section>
    );
}

export default Barbers;
