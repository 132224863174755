import React from 'react';
import './Appointment.scss';

function Appointment(props) {
    return (
        <section className="screen appointment-m">
            <span className="appointment-button wlaunchWidgetLoaderControl">
                Записатись
            </span>
        </section>
);
}

export default Appointment;
