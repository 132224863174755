import React from 'react';
import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';
import './Location.scss';
import { GMAP_API_KEY } from '../../../services/data';

class Location extends React.Component {

    render() {
        const coords = {lat: 50.419894, lng: 30.519032};
        const styles = {
            width: '100vw',
            height: '100%'

        };
        return (
            <section className="screen location-m" id="location">
                <h1>Як нас знайти?</h1>
                <div className="map-wrap">
                  <Map
                      google={this.props.google}
                      style={styles}
                      zoom={17}
                      initialCenter={coords}
                      scrollwheel={false}
                  >
                      <Marker position={coords} />
                  </Map>
                </div>
            </section>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: (GMAP_API_KEY)
})(Location)
